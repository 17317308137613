<template>
	<v-menu
		v-model="state"
		:close-on-content-click="false"
		transition="scale-transition"
		min-width="auto"
		:disabled="disabled"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:prepend-icon="prependIconResource"
				:value="displayValue"
				:label="label"
				:rules="rules"
				readonly
				v-bind="attrs"
				v-on="!disabled ? on : {}"
				:disabled="disabled"
			/>
		</template>
		<v-date-picker
			v-model="pickerValue"
			year-icon="icon-calendar"
			prev-icon="icon-angle-left"
			next-icon="icon-angle-right"
			@input="state = false"
			@change="changeAction"
			no-title
		/>
	</v-menu>
</template>

<script>
import moment from "moment";

export default {
	props: {
		value: Object,
		showPrependIcon: Boolean,
		prependIcon: String,
		label: String,
		rules: Array,
		disabled: Boolean,
	},
	data() {
		return {
			state: false,
			pickerValue: null,
		};
	},
	computed: {
		prependIconResource() {
			return this.showPrependIcon ? this.prependIcon || "icon-calendar" : undefined;
		},
		displayValue() {
			return this.value ? this.value.format(this.$t("$format.date")) : "";
		},
	},
	methods: {
		handleProps() {
			if (this.value) {
				this.pickerValue = this.value.format("YYYY-MM-DD");
			}
		},
		changeAction(val) {
			this.$emit("input", val ? moment(val) : null);
		},
	},
	created() {
		this.handleProps();
	},
};
</script>
