<template>
	<v-container tag="section" aria-label="Tracking Section" class="container">
		<v-row class="d-flex align-center">
			<v-col
				cols="12"
				md="6"
				class="text-container text-center justify-center justify-md-start text-md-left"
				tag="section"
			>
				<v-card-subtitle
					:class="isMobile ? 'v-typography--title1' : 'v-typography--title2'"
					class="accent--text title-with-line pa-0 pl-1"
					tag="h2"
				>
					{{ $t(`$landing.$tracking.tracking_title`) }}
				</v-card-subtitle>

				<v-card-title
					:class="isMobile ? 'v-typography--title' : 'v-typography--title3'"
					class="pa-1 text-center justify-center text-md-left justify-md-start mt-2 mb-4 line-height"
					tag="h3"
				>
					{{ $t(`$landing.$tracking.control_title`) }}
				</v-card-title>

				<v-card-subtitle
					:class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'"
					class="pa-1 mb-0 text-center justify-center text-md-left justify-md-start"
					tag="p"
				>
					{{ $t(`$landing.$tracking.tracking_description`) }}
				</v-card-subtitle>

				<v-list tag="ul" class="pl-0 bg_transparent">
					<v-list-item
						v-for="item in trackingFeatures"
						:key="item.text"
						:class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'"
						class="pa-1 d-flex align-center no-min-height text-center justify-center text-md-left justify-md-start"
						tag="li"
					>
						<img
							src="@/assets/red_check.webp"
							class="check-img mr-2"
							alt="Check icon indicating a benefit"
							loading="lazy"
							height="17"
							width="17"
						/>
						{{ $t(item.text) }}
					</v-list-item>
				</v-list>
			</v-col>
			<v-col cols="12" md="6" class="d-flex justify-center">
				<v-img
					src="https://fs.antrego.net/landing/container_tracking.webp"
					class="image-style"
					alt="Illustration showing the features and benefits of the Antrego tracking platform"
					loading="lazy"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: { isMobile: Boolean },
	data() {
		return {
			trackingFeatures: [
				{ icon: "icon-check", text: "$landing.$tracking.real_time_alerts" },
				{ icon: "icon-check", text: "$landing.$tracking.secure_data_handling" },
			],
		};
	},
};
</script>

<style scoped>
.line-height {
	line-height: 30px !important;
}
.image-style {
	width: 100%;
	max-width: 600px;
	height: auto;
	border-radius: 10px;
}

.title-with-line {
	position: relative;
	display: inline-block;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.4);
	transform-origin: left;
	margin-left: 4px;
}

.check-img {
	width: 17px;
	height: 17px;
}

.v-main {
	max-width: 100%;
	overflow-x: hidden;
}
@media (min-width: 1904px) {
	.container {
		max-width: 1185px !important;
	}
}
@media (max-width: 960px) {
	.title-with-line::after {
		height: 0px;
	}
}
</style>
