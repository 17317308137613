<template>
	<page-template>
		<template slot="buttons"></template>
		<template slot="content">
			<v-row class="mt-6" no-gutters align="center" justify="space-between">
				<v-col cols="auto">
					<v-card-title class="pa-0 font-weight-bold">
						{{ $t("subscription") }}
					</v-card-title>
				</v-col>
			</v-row>
			<v-card class="ant-card pa-2 pb-0 mt-4" outlined elevation="0">
				<v-progress-linear v-if="isBusy" color="secondary" />
				<template v-else>
					<v-container v-if="oid" style="max-width: 780px">
						<payment-status :oid="oid" :pid="antProductId" />
					</v-container>
					<v-container v-else style="max-width: 1280px">
						<v-stepper v-model="stepper" class="ant-card">
							<v-stepper-header>
								<v-stepper-step :complete="stepper > 1" step="1" complete-icon="icon-check">
									{{ $t("packages") }}
								</v-stepper-step>
								<v-divider />
								<v-stepper-step :complete="stepper > 2" step="2">
									{{ $t("payment") }}
								</v-stepper-step>
							</v-stepper-header>
							<v-stepper-items>
								<v-stepper-content step="1" class="pt-2">
									<v-row>
										<ant-products @nextAction="nextAction" />
									</v-row>
								</v-stepper-content>
								<v-stepper-content step="2" class="pt-2">
									<payment
										v-if="isPaymentActivated || stepper == 2"
										@onError="onError"
										@previousAction="previousAction"
										@paymentActivate="paymentActivated"
										:antProduct="selectedAntProduct"
									/>
									<v-container v-else />
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</v-container>
				</template>
			</v-card>
		</template>
	</page-template>
</template>

<script>
import AntProducts from "./partial/AntProducts.vue";
import PageTemplate from "../../templates/PageTemplate.vue";
import Payment from "./partial/Payment.vue";
import PaymentStatus from "./partial/PaymentStatus.vue";
import remote from "../../../data/remote";
import uiMixin from "../../../store/interfaces/ui.mixin";

export default {
	components: {
		PageTemplate,
		AntProducts,
		Payment,
		PaymentStatus,
	},
	mixins: [uiMixin],

	data() {
		return {
			stepper: 1,
			selectedAntProduct: null,
			isBusy: false,
			isPaymentActivated: false,
			oid: null,
		};
	},
	methods: {
		onError(e) {
			this.showAlertDialog({
				message: e ?? this.$t("$serviceMessage.something_went_wrong"),
				buttonText: this.$t("hide"),
			});
		},
		nextAction(item) {
			if (this.stepper == 1) {
				this.stepper++;
				this.selectedAntProduct = item;
			}
		},
		previousAction() {
			if (this.stepper > 1) this.stepper--;
		},
		paymentActivated() {
			this.isPaymentActivated = true;
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.oid = this.$route.query.oid;
		this.paymentStatus = this.$route.query.status;
		this.antProductId = this.$route.query.pid;
		if (this.paymentStatus == "fail") {
			remote.payment.delete(this.oid, {
				onSuccess: (result) => {},
				onFail: (e) => console.error(e),
			});
		}
	},
};
</script>
