import Model from "../_model";

export default class ProductModel extends Model {
	constructor(provider) {
		super(provider, "products");
	}

	/**
	 * @param	{ text, productGroupId }	filter
	 * @param { length, size } pager
	 * @param { property, 'asc' | 'desc' } sorter
	 */
	filter({ filter, pager, sorter, onSuccess, onFail }) {
		return super.filter({
			params: {
				text: filter ? filter.text : undefined,
				productGroupId: filter ? filter.productGroupId : undefined,
			},
			pager,
			sorter,
			onSuccess,
			onFail,
		});
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	create(product, { onSuccess, onFail }) {
		const body = {
			name: product.name,
			code: product.code,
			units: [],
			productGroupId: product.productGroupId,
		};
		for (const unit of product.units) body.units.push({ code: unit.code, name: unit.name });
		return super.create(body, { onSuccess, onFail });
	}

	update(product, { onSuccess, onFail }) {
		const body = {
			name: product.name,
			code: product.code,
			productGroupId: product.productGroupId,
			status: product.status,
			units: product.units,
		};
		return super.update(body, { id: product.id, onSuccess, onFail });
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({ id, onSuccess, onFail });
	}
	importBulk(products, { onSuccess, onFail }) {
		return this._post(("products/import"), products, {
			onSuccess,
			onFail,
		});
	}
}
