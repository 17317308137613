import Model from "../_model";

export default class CarrierModel extends Model {
	constructor(provider) {
		// "carriers" is the resource endpoint
		super(provider, "carriers");
	}

	_parse(object) {
		// Optionally parse dates
		if (object.createdAt) object.createdAt = this.parseDateTime(object.createdAt);
		if (object.updatedAt) object.updatedAt = this.parseDateTime(object.updatedAt);
		return super._parse(object);
	}

	// Get all carriers without filtering/paging/sorting.
	getAll({ onSuccess, onFail }) {
		super._get("carriers", { onSuccess, onFail });
	}

	// Find a specific carrier by ID.
	find(id, { onSuccess, onFail } = {}) {
		return super.find({ id, onSuccess, onFail });
	}

	// Create a new carrier.
	create(carrier, { onSuccess, onFail } = {}) {
		const body = {
			name: carrier.name,
			prefix: carrier.prefix,
			containerPrefixes: carrier.containerPrefixes, // expected to be an array
			website: carrier.website,
			settings: carrier.settings || {},
		};
		return super.create(body, { onSuccess, onFail });
	}

	// Update an existing carrier.
	update(carrier, { onSuccess, onFail } = {}) {
		const body = {
			name: carrier.name,
			prefix: carrier.prefix,
			containerPrefixes: carrier.containerPrefixes,
			website: carrier.website,
			settings: carrier.settings,
		};
		return super.update(body, { id: carrier.id, onSuccess, onFail });
	}

	// Delete a carrier by ID.
	delete(id, { onSuccess, onFail } = {}) {
		return super.delete({ id, onSuccess, onFail });
	}
}
