<template>
	<v-main>
		<div class="d-flex flex-column" style="min-height: 90vh; width: 100%">
			<landing-header
				@goTo="goTo"
				@switchDrawerVisibility="switchDrawerVisibility"
				:showDrawer="showDrawer"
				:isMobile="isMobile"
				:isLanding="false"
			/>
			<landing-drawer-menu
				:value="showDrawer"
				@input="switchDrawerVisibility"
				@goTo="goTo"
				@switchDrawerVisibility="switchDrawerVisibility"
			/>

			<div class="flex-grow-1 d-flex align-center justify-center mt-16" style="width: 100%">
				<v-card class="p-0" style="width: 100%">
					<v-divider class="mb-4" />

					<v-toolbar flat dense class="mb-2">
						<v-select
							:items="templates"
							item-text="name"
							item-value="id"
							v-model="selectedTemplateId"
							:label="$t('template')"
							:loading="loadingTemplates"
							append-icon="icon-angle-down"
							@change="onTemplateChange"
							dense
							hide-details
							style="max-width: 300px"
							:menu-props="{ offsetY: true }"
						/>
						<v-spacer />
						<v-btn color="primary" @click="downloadPdf" :disabled="!isIframeReady">
							{{ $t("download_pdf") }}
						</v-btn>
					</v-toolbar>

					<div>
						<iframe ref="reactIframe" :src="iframeSrc" frameborder="0" class="full-iframe"></iframe>
					</div>
				</v-card>
			</div>

			<landing-footer @goTo="goTo" :isMobile="isMobile" />
		</div>
	</v-main>
</template>

<script>
import LandingHeader from "../partial/LandingHeader.vue";
import LandingFooter from "../partial/LandingFooter.vue";
import LandingDrawerMenu from "../partial/LandingDrawerMenu.vue";
import uiMixin from "../../../../store/interfaces/ui.mixin";

const ALLOWED_ORIGIN = process.env.VUE_APP_PDF_CREATE_URL_ROOT;

export default {
	name: "DocumentPdfViewer",
	mixins: [uiMixin],
	components: {
		LandingHeader,
		LandingFooter,
		LandingDrawerMenu,
	},
	data() {
		return {
			iframeSrc: ALLOWED_ORIGIN,
			templates: [],
			selectedTemplateId: null,
			loadingTemplates: false,
			isIframeReady: false,
			isDownloadRequested: false,
			showDrawer: false,
		};
	},
	computed: {
		selectedTemplate() {
			return this.templates.find((template) => template.id === this.selectedTemplateId);
		},
	},
	methods: {
		goTo(tag) {
			this.$nextTick(() => document.getElementById(tag).scrollIntoView({ behavior: "smooth" }));
		},
		switchDrawerVisibility(val) {
			this.showDrawer = val;
		},
		async fetchTemplates() {
			this.loadingTemplates = true;
			try {
				const response = await fetch("/package_list.json");
				const response2 = await fetch("/proforma.json");
				const package_list = await response.json();
				const proforma = await response2.json();
				this.templates.push(package_list);
				this.templates.push(proforma);
				this.selectedTemplateId = this.templates[0]?.id;
			} catch (error) {
				console.error("Error fetching templates:", error);
			} finally {
				this.loadingTemplates = false;
			}
		},

		onTemplateChange() {
			this.loadTemplate();
		},
		populateTemplate(template) {
			return template;
		},
		loadTemplate() {
			if (!this.selectedTemplate) {
				console.warn("No template selected – skipping loadTemplate()");
				return;
			}
			const iframe = this.$refs.reactIframe;
			if (iframe && iframe.contentWindow) {
				let populatedTemplate;
				try {
					populatedTemplate = this.populateTemplate(this.selectedTemplate);
				} catch (error) {
					console.error("Error populating template:", error);
					return;
				}
				const safeData = JSON.parse(JSON.stringify(populatedTemplate));
				iframe.contentWindow.postMessage({ type: "LOAD_TEMPLATE", data: safeData }, ALLOWED_ORIGIN);
			} else {
				console.error("Iframe not found");
			}
		},
		postMessageToIframe(message) {
			const iframe = this.$refs.reactIframe;
			if (iframe && iframe.contentWindow) {
				iframe.contentWindow.postMessage(message, ALLOWED_ORIGIN);
			}
		},
		downloadPdf() {
			this.isDownloadRequested = true;
			this.postMessageToIframe({ type: "REQUEST_PDF_DATA" });
		},
		downloadPdfData(pdfBase64) {
			const byteString = window.atob(pdfBase64);
			const arrayBuffer = new ArrayBuffer(byteString.length);
			const uint8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
				uint8Array[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([arrayBuffer], { type: "application/pdf" });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "document.pdf";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		},
		handleMessage(event) {
			if (event.origin + "/" !== ALLOWED_ORIGIN) return;
			const messageData = event.data || {};

			if (messageData.type === "READY") {
				this.isIframeReady = true;
				if (this.selectedTemplateId) {
					this.loadTemplate();
				}
			}

			if (messageData.type === "PDF_DATA" && messageData.pdfBase64) {
				if (this.isDownloadRequested) {
					this.downloadPdfData(messageData.pdfBase64);
					this.isDownloadRequested = false;
				}
			}
		},
	},
	mounted() {
		window.addEventListener("message", this.handleMessage);
		this.fetchTemplates();
	},
	beforeUnmount() {
		window.removeEventListener("message", this.handleMessage);
	},
};
</script>

<style scoped>
.full-iframe {
	width: 100%;
	height: calc(100vh - 160px);
	border: none;
	overflow: hidden;
}
</style>
