import HttpModule from "../_http_module";

export default class ProductGroupModel extends HttpModule {
	getMyProductGroupTree({ onSuccess, onFail }) {
		return super._get("product_group_tree", {
			onSuccess,
			onFail,
		});
	}

	create(productGroup, parentId, { onSuccess, onFail }) {
		const body = { name: productGroup.name };
		return super._post(`product_groups/${parentId || "root"}`, body, {
			onSuccess,
			onFail,
		});
	}

	update(productGroup, targetGroupId, { onSuccess, onFail }) {
		const body = { name: productGroup.name };
		let url = `product_groups/${productGroup.id}`;
		if (targetGroupId) url += `/move_to/${targetGroupId}`;
		return super._put(url, body, { onSuccess, onFail });
	}

	delete(id, { onSuccess, onFail }) {
		return super._delete(`product_groups/${id}`, { onSuccess, onFail });
	}
	importBulk(data, { onSuccess, onFail }) {
		return super._post("product_groups/import", data, { onSuccess, onFail });
	}
}
