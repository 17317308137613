<template>
	<v-container>
		<v-card-text>
			<v-progress-linear v-if="loading" color="accent" />

			<v-tabs v-model="currentIndex" centered color="anchor">
				<v-tab
					v-for="(tab, i) in tabs"
					:key="i"
					@click="changeTab(tab)"
					:class="['v-typography-title1 tab-item', 'rounded-lg', currentIndex === tab ? 'selected-tab' : '']"
				>
					{{ $t(tab) }}
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="currentIndex" class="py-4">
				<v-tab-item v-for="(tab, j) in tabs" :key="j">
					<v-row v-if="filteredProducts[tab].length" align="center" justify="center">
						<v-col
							class="mt-4"
							v-for="(product, k) in filteredProducts[tab]"
							:key="k"
							cols="12"
							sm="6"
							md="5"
							lg="4"
							xl="4"
						>
							<v-card
								class="py-8 px-8 card"
								elevation="0"
								:color="product === selectedProduct ? 'accent' : 'background'"
								@click="selectAction(product)"
								align="start"
								justify="start"
							>
								<div v-if="product.name === 'yearly'" class="red-banner">
									{{ $t("$landing.save_percentage") }}
								</div>
								<v-card-text
									:class="[product === selectedProduct ? 'white--text' : 'black--text', 'v-typography--paragraph']"
								>
									{{ $t(product.category) }}
								</v-card-text>

								<v-card-text
									:class="[product === selectedProduct ? 'white--text' : 'black--text', 'v-typography--title5 my-4']"
								>
									<template v-if="product.name === 'yearly'">
										<div class="price-section">
											<span
												class="original-monthly-price"
												v-if="monthlyPriceMap[product.category]"
												:style="{ color: product === selectedProduct ? 'white' : 'gray' }"
											>
												${{ getPrice(monthlyPriceMap[product.category]) }}
											</span>

											<span>
												${{ splitPrice(product.price / 12).dollars
												}}<span class="cents">.{{ splitPrice(product.price / 12).cents }}</span>
											</span>
											<span class="v-typography--body">{{ "/" + $t("month_lowercase") }}</span>
										</div>
									</template>
									<template v-else>
										<div class="price-section">
											<span> ${{ splitPrice(product.price).dollars }} </span>
										</div>
									</template>
								</v-card-text>

								<v-card-text
									v-for="(content, l) in product.contents"
									:key="l"
									:class="[product === selectedProduct ? 'white--text' : 'black--text', 'v-typography--body py-2']"
								>
									<v-icon size="18" class="mr-2" :color="product === selectedProduct ? 'white' : 'black'">
										icon-check
									</v-icon>
									{{ getContentDetail(content) }}
								</v-card-text>

								<v-card-actions>
									<v-spacer />
									<v-btn large class="py-6 flex-grow-1 px-4 px-md-16 mt-4" elevation="0">
										{{ product === selectedProduct ? $t("selected") : $t("select") }}
									</v-btn>
									<v-spacer />
								</v-card-actions>

								<v-card-subtitle :class="[product === selectedProduct ? 'white--text' : 'black--text']">
									{{
										$t("$antProduct.renewAtPricePerUnit", {
											currency: "$",
											price: product.price,
											unit: $t(product.name === "yearly" ? "year_lowercase" : "month_lowercase"),
										})
									}}
								</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>

		<v-card-subtitle class="py-2 v-typography--body">
			<v-icon class="mr-2" color="error">icon-check</v-icon>
			{{ $t("$landing.cancel_anytime") }}
		</v-card-subtitle>

		<v-card-subtitle class="py-2 v-typography--body">
			<v-icon class="mr-2" color="error">icon-check</v-icon>
			{{ $t("$landing.customize_package") }}
		</v-card-subtitle>

		<v-card-actions>
			<v-spacer />
			<v-btn color="accent" outlined large elevation="0" class="px-4" @click="nextAction" :disabled="!selectedProduct">
				{{ $t("select") }}
				<v-icon dark> icon-angle-right </v-icon>
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import remote from "../../../../data/remote/index";
import { antModule, antProductCategoryTypes } from "../../../../utils/enum";

export default {
	data() {
		return {
			selectedProduct: null,
			currentIndex: 1,
			tabs: ["monthly", "yearly"],
			loading: true,
			antProducts: null,
			filteredProducts: {
				monthly: [],
				yearly: [],
			},
			monthlyPriceMap: {},
		};
	},
	computed: {
		...mapGetters(["countryCode"]),
		...mapState(["corporation", "countries"]),
	},

	methods: {
		buildMonthlyPriceMap() {
			this.antProducts.forEach((product) => {
				if (product.name === "monthly") {
					this.monthlyPriceMap[product.category] = product.price;
				}
			});
		},
		splitPrice(price) {
			const [dollars, cents] = parseFloat(price).toFixed(2).split(".");
			return { dollars, cents };
		},
		getAntProducts() {
			this.loading = true;
			remote.antProduct.filter({
				onSuccess: (result) => {
					this.antProducts = result.items;
					this.buildMonthlyPriceMap();

					this.changeTab(this.tabs[this.currentIndex]);
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		changeTab(selectedTab) {
			const periodProducts = this.antProducts.filter(
				(p) => p.name !== antProductCategoryTypes.Free && p.name === selectedTab
			);
			this.filteredProducts[selectedTab] = [...periodProducts];
		},
		selectAction(product) {
			this.selectedProduct = product;
		},
		nextAction() {
			this.$emit("nextAction", this.selectedProduct);
		},
		getPrice(price) {
			return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},

		getContentDetail(content) {
			switch (content.module) {
				case antModule.Shipment:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("shipment"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("shipment"),
						});
				case antModule.Partner:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("commercial_partner"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("commercial_partner"),
						});
				case antModule.Storage:
					return this.$t("$antProduct.limitGBStorage", {
						limit: content.limit,
					});
				case antModule.User:
					return this.$t(content.limit <= 1 ? "$antProduct.limitUser" : "$antProduct.limitUsers", {
						limit: content.limit,
					});
				default:
					return "";
			}
		},
	},
	watch: {
		currentIndex(newVal) {
			this.changeTab(this.tabs[newVal]);
		},
	},
	created() {
		this.getAntProducts();
	},
};
</script>

<style scoped>
.price-section {
	font-size: 36px;
	font-weight: 800;
	display: inline-flex;
	align-items: baseline;
	justify-content: center;
	gap: 4px;
	white-space: nowrap;
}
.original-monthly-price {
	color: gray;
	text-decoration: line-through;
	vertical-align: middle;
}
.cents {
	font-size: 0.5em;
	font-weight: 800;
}

.tab-item {
	text-transform: none;
	margin: 0 4px;
	padding: 8px 30px;
}

.selected-tab {
	background-color: #005d8f;
	color: white;
}

.card {
	border: rounded;
	border-radius: 20px;
	transition: background-color 0.3s ease, color 0.3s ease;
	position: relative;
}

.red-banner {
	position: absolute;
	top: -10px;
	right: 20px;
	border-radius: 20px !important;
	background-color: #cc2a21;
	color: white;
	padding: 4px 12px;
	font-size: 0.8rem;
	z-index: 1;
}
@media (max-width: 750px) {
	.price-section {
		font-size: 28px;
		gap: 4px;
	}
}
</style>
