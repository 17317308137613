<template>
	<v-autocomplete
		v-model="currentValue"
		:items="currentOptions"
		item-text="optionName"
		item-value="id"
		outlined
		:label="label || $t('company')"
		:disabled="!ready || disabled"
		:loading="!ready && !disabled"
		:clearable="clearable"
		clear-icon="icon-cross"
		append-icon="icon-angle-down"
		append-outer-icon="icon-plus"
		:rules="rules"
		:prepend-icon="prependIcon"
		@change="changeAction"
		@click:append-outer="openDialogHandler"
	/>
</template>

<script>
import remote from "../../data/remote";

export default {
	props: ["value", "eager", "options", "label", "clearable", "disabled", "rules", "prepend-icon"],
	data() {
		return {
			currentValue: null,
			currentOptions: [],
			ready: false,
		};
	},
	watch: {
		currentValue(val) {
			this.$emit("input", val);
		},
		value(val) {
			this.currentValue = val || null;
		},
		options(val) {
			this.handleProps();
		},
	},
	methods: {
		openDialogHandler() {
			this.$emit("openDialog");
		},
		handleProps() {
			this.currentValue = this.value || null;
			if (this.options) {
				this.handleOptions(this.options);
				this.ready = true;
			} else if (this.eager) this.loadOptions();
		},
		handleOptions(options) {
			this.currentOptions = options;
			for (const item of this.currentOptions) {
				const name =
					item.name[this.$lang] && item.name[this.$lang].length ? item.name[this.$lang] : item.name["_default"];
				item.optionName = item.code ? `${item.code} - ${name}` : name;
			}
		},
		loadOptions() {
			remote.products.filter({
				onSuccess: (result) => {
					this.handleOptions(result.items);
					this.ready = true;
				},
			});
		},
		changeAction(id) {
			this.$emit(
				"change",
				this.currentOptions.find((i) => i.id === id)
			);
		},
	},
	created() {
		this.currentValue = this.value || null;
		this.handleProps();
	},
};
</script>
