<template>
	<v-dialog v-model="value" persistent :fullscreen="fullscreen" :max-width="fullscreen ? undefined : dialogMaxWidth">
		<v-card>
			<v-card-title>
				{{ title ?? $t("dialog") }}
				<v-spacer />
				<v-btn icon plain @click="closeAction">
					<v-icon>icon-cross</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mx-6 mb-4" />

			<v-card-text class="px-6 pb-0">
				<slot name="body" />
			</v-card-text>

			<v-card-actions class="pa-6" v-if="primaryButtonText || secondaryButtonText || deleteButtonText">
				<v-btn v-if="deleteButtonText" class="button-padding" color="red" outlined @click="deleteAction">
					{{ deleteButtonText }}
				</v-btn>
				<v-spacer />
				<v-btn v-if="secondaryButtonText" class="button-padding" color="accent" outlined @click="secondaryAction">
					{{ secondaryButtonText }}
				</v-btn>
				<v-btn v-if="primaryButtonText" class="button-padding" color="accent" @click="primaryAction">
					{{ primaryButtonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		title: String,
		primaryButtonText: String,
		secondaryButtonText: String, // for create product action
		deleteButtonText: String, // for delete action
		dialogType: String,
		fullscreen: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value(val) {
			if (!val) {
				this.$emit("closed");
			}
		},
	},
	methods: {
		primaryAction() {
			this.$emit("primaryButtonAction", true);
		},
		secondaryAction() {
			this.$emit("secondaryButtonAction", true);
		},
		deleteAction() {
			this.$emit("deleteButtonAction", true);
		},
		closeAction() {
			this.$emit("closed", false);
		},
	},
	computed: {
		dialogMaxWidth() {
			if (this.dialogType === "xlarge") {
				return "1200px";
			} else if (this.dialogType === "large") {
				return "800px";
			} else {
				return "500px";
			}
		},
	},
};
</script>
