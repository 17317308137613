<template>
	<v-menu transition="slide-y-transition" offset-y rounded="xl">
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon plain v-bind="attrs" v-on="on" class="mr-2">
				<v-icon color="black">icon-bell</v-icon>
			</v-btn>
		</template>
		<v-list width="300">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>Your shipment has arrived. </v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>You have a new Proforma Invoice.</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	name: "NotificationsMenu",
	data() {
		return {
			menu: false,
		};
	},
};
</script>
