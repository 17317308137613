<template>
	<ext-dialog
		v-model="value"
		:title="$t(isLandShipment ? 'vehicle' : 'container')"
		:primaryButtonText="$t('save')"
		:secondaryButtonText="showDeleteButton ? $t('delete') : null"
		@primaryButtonAction="saveButtonAction"
		@secondaryButtonAction="deleteButtonAction"
		@closed="cancelButtonAction"
		:dialogType="'default'"
	>
		<template v-slot:body>
			<div v-if="currentItem">
				<v-form v-model="valid" ref="form">
					<v-autocomplete
						v-model="currentItem.carrier"
						class="mb-4"
						:items="carrierOptions"
						outlined
						hide-details
						item-text="name"
						item-value="prefix"
						:label="$t('carrier')"
						append-icon="icon-angle-down"
					/>
					<v-text-field
						:disabled="currentItem.id ? !iAmOwner : false"
						v-model="currentItem.code"
						:label="$t(isLandShipment ? '$shipment.$vehicle.license_number' : '$shortening.number')"
						:rules="rules.code"
						class="mb-2"
					/>
					<v-autocomplete
						v-model="currentItem.status"
						outlined
						hide-details
						:items="statusOptions"
						item-text="text"
						item-value="value"
						:label="$t('status')"
						:rules="rules.status"
						class="pt-4"
						append-icon="icon-angle-down"
						clearable
					>
						<template v-slot:item="{ item }">
							<v-chip :style="getStatusStyles(item.value)" small>
								{{ item.text }}
							</v-chip>
						</template>
						<template v-slot:selection="{ item }">
							<v-chip :style="getStatusStyles(item.value)" small>
								{{ item.text }}
							</v-chip>
						</template>
					</v-autocomplete>
					<v-checkbox
						v-model="currentItem.autoDetect"
						:label="$t('auto_detect')"
						hide-details
						class="mb-4"
						:disabled="currentItem.carrier === 'other'"
						on-icon="icon-checkbox-checked"
						off-icon="icon-checkbox-unchecked"
					/>
					<date-picker-moment
						v-model="currentItem.estimatedArrivalDay"
						:label="$t('estimated_arrival_day')"
						:disabled="currentItem.autoDetect"
					/>
					<v-text-field :rules="rules.note" v-model="currentItem.note" :label="$t('note')" />
				</v-form>
			</div>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import cloneDeep from "lodash.clonedeep";
import DatePickerMoment from "../../../controls/DatePickerMoment";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	props: {
		value: Boolean,
		item: Object,
		shipment: Object,
		iAmOwner: Boolean,
		isCustomsAgency: Boolean,
	},
	components: {
		DatePickerMoment,
		ExtDialog,
	},
	data() {
		return {
			valid: null,
			saving: false,
			deleting: false,
			currentItem: null,
			carrierOptions: [],
			statusOptions: [
				{
					text: this.$t("$shipment.$containerStatus.reserved"),
					value: "reserved",
				},
				{
					text: this.$t("$shipment.$containerStatus.on_load"),
					value: "on_load",
				},
				{
					text: this.$t("$shipment.$containerStatus.on_way"),
					value: "on_way",
				},
				{
					text: this.$t("$shipment.$containerStatus.arrived"),
					value: "arrived",
				},
				{
					text: this.$t("$shipment.$containerStatus.off_load"),
					value: "off_load",
				},
			],
			containerTemplate: {
				code: "",
				status: "reserved",
				estimatedArrivalDay: null,
				note: "",
				id: null,
				carrier: "other",
				autoDetect: false,
			},
			rules: {
				code: [
					(v) => {
						if (!v) return Boolean(v) || this.$t("$validation.required", { item: this.$t("code") });
						else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("code"),
								length: "50",
							});
						return true;
					},
				],
				status: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("status") })],
				note: [
					(v) => {
						if (v && v.length > 240)
							return this.$t("$validation.max_length", {
								item: this.$t("note"),
								length: "240",
							});
						return true;
					},
				],
			},
		};
	},

	computed: {
		...mapGetters(["company"]),
		iAmSenderCompany() {
			return this.shipment && this.company ? this.company.id === this.shipment.sender.company.referenceId : false;
		},
		isLandShipment() {
			return this.shipment.method === "land";
		},
		showDeleteButton() {
			return this.currentItem && this.currentItem.id && !this.isCustomsAgency;
		},
	},
	
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
		"currentItem.code"(newCode) {
			if (newCode && newCode.length >= 4 && this.carrierOptions.length) {
				const codePrefix = newCode.substring(0, 4).toUpperCase();
				const matchingCarrier = this.carrierOptions.find((c) => {
					if (!Array.isArray(c.containerPrefixes)) return false;
					return c.containerPrefixes.some((prefix) => prefix.toUpperCase() === codePrefix);
				});
				if (matchingCarrier) {
					this.currentItem.carrier = matchingCarrier.prefix;
				}
			}
		},
	},
	methods: {
		async getCarriers() {
			try {
				remote.carriers.getAll({
					onSuccess: (result) => {
						this.carrierOptions = result.items || result;
						this.carrierOptions.unshift({
							name: this.$t("other"),
							prefix: "other",
							containerPrefixes: [],
						});
					},
				});
			} catch (error) {
				console.error("Error loading carriers:", error);
			}
		},
		handleProps() {
			this.currentItem = cloneDeep(this.item || this.containerTemplate);
			if (!this.currentItem.carrier) {
				this.currentItem.carrier = "other";
			}
			if (typeof this.currentItem.autoDetect === "undefined") {
				this.currentItem.autoDetect = false;
			}
			if (this.currentItem.code && this.currentItem.code.length >= 4 && this.carrierOptions.length) {
				const codePrefix = this.currentItem.code.substring(0, 4).toUpperCase();
				const matchingCarrier = this.carrierOptions.find(
					(c) =>
						Array.isArray(c.containerPrefixes) &&
						c.containerPrefixes.some((prefix) => prefix.toUpperCase() === codePrefix)
				);
				if (matchingCarrier) {
					this.currentItem.carrier = matchingCarrier.prefix;
				}
			}
		},
		reset() {
			if (this.currentItem && !this.currentItem.id) {
				this.containerTemplate.estimatedArrivalDay = this.currentItem.estimatedArrivalDay;
				this.containerTemplate.status = this.currentItem.status;
				this.containerTemplate.carrier = this.currentItem.carrier;
			}
			this.currentItem = null;
			this.saving = false;
			this.deleting = false;
		},
		saveButtonAction() {
			if (this.valid) {
				if (this.currentItem.autoDetect) {
					this.currentItem.estimatedArrivalDay = null;
				}
				this.saving = true;
				if (this.currentItem.id) {
					remote.shipments.containers.update(this.currentItem, this.shipment.id, {
						onSuccess: () => {
							this.saving = false;
							this.$emit("saved");
						},
						onFail: (error) => {
							this.saving = false;
							if (error) console.error(error);
						},
					});
				} else {
					remote.shipments.containers.create(this.currentItem, this.shipment.id, {
						onSuccess: () => {
							this.saving = false;
							this.$emit("saved");
						},
						onFail: (error) => {
							this.saving = false;
							if (error) console.error(error);
						},
					});
				}
			} else {
				this.$refs.form.validate();
			}
		},
		deleteButtonAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.containers.delete(this.currentItem.id, this.shipment.id, {
						onSuccess: () => {
							this.deleting = false;
							this.$emit("deleted");
						},
						onFail: (error) => {
							this.deleting = false;
							if (error) console.error(error);
						},
					});
				},
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
			this.currentItem = null;
			this.$emit("closed");
		},
		getStatusStyles(status) {
			switch (status) {
				case "reserved":
					return {
						backgroundColor: "rgba(128, 128, 128, 0.5)",
						color: "#606060",
					};
				case "on_load":
					return {
						backgroundColor: "rgba(0, 123, 255, 0.5)",
						color: "#0056b3",
					};
				case "on_way":
					return {
						backgroundColor: "rgba(255, 165, 0, 0.5)",
						color: "#cc8400",
					};
				case "arrived":
					return {
						backgroundColor: "rgba(0, 128, 0, 0.5)",
						color: "#006400",
					};
				case "off_load":
					return {
						backgroundColor: "rgba(255, 0, 0, 0.5)",
						color: "#8b0000",
					};
				default:
					return {
						backgroundColor: "rgba(128, 128, 128, 0.5)",
						color: "#808080",
					};
			}
		},
	},
	async mounted() {
		await this.getCarriers();
		this.handleProps();
	},
};
</script>
