<template>
	<v-container>
		<common-table
			:options.sync="tableOptions"
			:headers="headers"
			:items="employees"
			:items-length="employees.length"
			:items-per-page="tableOptions.itemsPerPage"
			:items-per-page-options="tableFooterProps.itemsPerPageOptions"
			@delete-item="deleteEmployee"
			hide-default-footer
			style="cursor: pointer"
			disable-sort
		>
			<template #item.type="{ item }">
				<v-select
					v-model="item.type"
					:items="typeOptions"
					item-value="value"
					item-text="text"
					outlined
					dense
					hide-details
					:disabled="item.user.id === user.id"
					append-icon="icon-angle-down"
					:menu-props="{ offsetY: true }"
					@change="typeChangeAction(item)"
				/>
			</template>
		</common-table>

		<v-btn class="mt-6" color="accent" outlined large elevation="0" @click="invitationDialogVisibility = true">
			<v-icon class="icon-plus mr-2" start size="medium"> </v-icon>
			{{ $t("add_new_employee") }}
		</v-btn>

		<invitation-dialog v-model="invitationDialogVisibility" :isEmployee="true" @saved="handleInvitationSaved" />
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CommonTable from "../../partials/CommonTable.vue";
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";
import InvitationDialog from './dialogs/InvitationDialog.vue';

export default {
	mixins: [uiMixin, paginationMixin],
	components: { CommonTable, InvitationDialog },
	data() {
		return {
			employees: [],
			invitationDialogVisibility: false,
			typeOptionsForCompany: [
				{ value: "admin", text: this.$t("$employeeType.admin") },
				{ value: "default", text: this.$t("$employeeType.default") },
			],
			typeOptionsForCustomsAgency: [
				{ value: "admin", text: this.$t("$employeeType.admin") },
				{
					value: "default",
					text: `${this.$t("$employeeType.exporter")} - ${this.$t("$employeeType.importer")}`,
				},
				{ value: "exporter", text: this.$t("$employeeType.exporter") },
				{ value: "importer", text: this.$t("$employeeType.importer") },
			],
			headers: [
				{ text: this.$t("first_name"), value: "user.firstName" },
				{ text: this.$t("last_name"), value: "user.lastName" },
				{ text: this.$t("email"), value: "user.email" },
				{ text: this.$t("type"), value: "type" },
				{ text: this.$t("actions"), value: "actions" },
			],
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState("auth", ["user"]),
		typeOptions() {
			if (this.company) return this.typeOptionsForCompany;
			else if (this.customsAgency) return this.typeOptionsForCustomsAgency;
		},
	},
	methods: {
		handleInvitationSaved() {
			this.loadItems();
		},
		loadItems() {
			remote.employees.getMyCompanyEmployees({
				onSuccess: (result) => {
					this.employees = result.items.sort((a, b) => (a.name < b.name ? -1 : 1));
					this.hideProgressBar();
				},
			});
		},
		typeChangeAction(employee) {
			this.showProgressBar();
			remote.employees.updateType(employee.id, employee.type, {
				onSuccess: (_) => this.loadItems(),
				onFail: (_) => this.loadItems(),
			});
		},
		deleteEmployee(employee) {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressBar();
					remote.employees.delete(employee.id, {
						onSuccess: () => {
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("employee"),
								}),
							});
							this.loadItems();
						},
					});
				},
			});
		},
	},
	created() {
		this.loadItems();
	},
};
</script>
