<template>
	<ext-dialog
		v-model="value"
		:title="$t('migrate_partner')"
		:primaryButtonText="$t('ok')"
		@primaryButtonAction="migrateButtonAction"
		@closed="cancelButtonAction"
	>
		<template #body>
			<div class="d-flex align-center mb-4">
				<v-icon small class="mr-2"> icon-comment-info </v-icon>
				<span>{{ $t("migrate_partner_description") }}</span>
			</div>
			<v-form v-model="valid" ref="form">
				<partner-select
					v-model="currentItem.selectedId"
					:label="$t('selected_partner')"
					eager="true"
					:types="[partnerTypes.AbstractCompany]"
					:rules="rules.selectedPartner"
				/>
				<partner-select
					v-model="currentItem.targetId"
					:label="$t('target_partner')"
					eager="true"
					:types="[partnerTypes.Company]"
					:rules="rules.targetPartner"
				/>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import PartnerSelect from "../../../controls/PartnerSelect.vue";
import remote from "../../../../data/remote";
import { partnerTypes } from "../../../../utils/enum";
import { mapState } from "vuex";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	mixins: [uiMixin],
	props: { value: Boolean, item: Object },
	components: { PartnerSelect, ExtDialog },
	data() {
		return {
			valid: null,
			currentItem: {
				selectedId: null,
				targetId: null,
			},
			rules: {
				selectedPartner: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("selected_partner"),
						}),
				],
				targetPartner: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("target_partner"),
						}),
				],
			},
		};
	},
	computed: {
		...mapState(["corporation"]),
		partnerTypes: () => partnerTypes,
	},
	watch: {
		value(val) {
			if (!val) {
				this.$nextTick(() => {
					if (this.$refs.form) {
						this.$refs.form.reset();
					}
				});
				this.currentItem = {
					selectedId: null,
					targetId: null,
				};
			}
		},
	},
	methods: {
		migrateButtonAction() {
			if (this.valid) {
				this.saving = true;
				remote.corporations.partners.migrate(this.corporation.id, this.currentItem, {
					onSuccess: (result) => {
						this.saving = false;
						this.$emit("migrated", result);
					},
					onFail: () => this.hideProgressDialog(),
				});
			} else {
				this.$refs.form.validate();
			}
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
	},
};
</script>
