<template>
	<page-template>
		<template slot="content">
			<div>
				<v-col cols="auto" class="d-flex align-center px-0">
					<v-card-title class="d-flex justify-start font-weight-bold px-0">
						{{ $t("product_groups") }}
					</v-card-title>
				</v-col>
				<v-card outlined elevation="0" class="mt-1 ant-card">
					<div class="d-flex align-center mt-6 ml-12">
						<v-row>
							<v-col cols="4">
								<p class="grey--text v-typography--table-header">{{ $t(`$productGroup.name`) }}</p>
							</v-col>
							<v-col cols="4" class="text-center">
								<p class="grey--text v-typography--table-header">
									{{ $t(`$productGroup.number_of_product`) }}
								</p>
							</v-col>
							<v-col cols="4" class="d-flex justify-end pr-10">
								<p class="grey--text v-typography--table-header">{{ $t("actions") }}</p>
							</v-col>
						</v-row>
					</div>
					<v-treeview
						open-all
						:items="productGroupTree.children"
						expand-icon="icon-angle-down"
						v-if="productGroupTree && productGroupTree.children.length"
						hoverable
					>
						<template v-slot:label="{ item }">
							<v-row @click="dialog.show(item)" style="cursor: pointer">
								<v-col cols="4" class="pa-6">
									{{ item.name[$lang] || item.name["_default"] }}
								</v-col>
								<v-col cols="4" class="text-center pa-6">
									{{ item.productCount }}
								</v-col>
								<v-col cols="4" class="d-flex justify-end">
									<v-btn text class="pa-6 mr-5" @click.stop="deleteItem(item)">
										<v-icon class="icon-delete" size="large"></v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-divider />
						</template>
					</v-treeview>
					<div
						v-else-if="productGroupTree"
						class="mt-10 mb-5 d-flex flex-column align-center justify-center text-center"
					>
						<v-icon class="icon-empty-state mb-2" size="28" color="black"></v-icon>
						<v-col>
							<p class="font-weight-bold mb-1 black--text">
								{{ $t("no_entity", { entityName: $t("product_group") }) }}
							</p>
							<p>{{ $t("no_entity_message", { entityName: $t("product_group") }) }}</p>
						</v-col>
					</div>
				</v-card>
			</div>

			<v-btn v-if="productGroupTree" class="mt-6" color="accent" outlined large elevation="0" @click="dialog.show()">
				<v-icon class="icon-plus mr-2" start size="medium"></v-icon>
				{{ $t("$productGroup.add_new_product_group") }}
			</v-btn>
			<v-btn class="mt-6 ml-2" color="accent" outlined large elevation="0" @click="triggerFileInput">
				<v-icon class="icon-import mr-2" start size="medium"></v-icon>
				{{ $t("import_excel") }}
			</v-btn>
			<input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" accept=".xlsx, .xls" />

			<product-group-dialog
				v-model="dialog.visibility"
				:item="dialog.item"
				:productGroupTree="productGroupTree"
				:maxParentCount="maxParentCount"
				@saved="dialog.saved"
				@closed="dialog.closed"
				@deleted="dialog.deleted"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../store/interfaces/ui.mixin";
import PageTemplate from "../templates/PageTemplate";
import ProductGroupDialog from "../partials/dialogs/ProductGroupDialog.vue";
import remote from "../../data/remote";
import * as XLSX from "xlsx";

export default {
	mixins: [uiMixin],

	components: {
		PageTemplate,
		ProductGroupDialog,
	},

	data() {
		return {
			maxParentCount: 5,
			productGroupTree: null,

			dialog: {
				visibility: false,
				item: null,
				show(item = null) {
					this.item = item;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: () => {
					this.loadProductGroupTree();
					this.dialog.hide();
				},
				closed: () => {
					this.dialog.hide();
				},
				deleted: () => {
					this.loadProductGroupTree();
					this.dialog.hide();
				},
			},
		};
	},

	created() {
		this.$trackPageView(this.$route.path);
		this.loadProductGroupTree();
	},

	methods: {
		deleteItem(item) {
			if (item.children && item.children.length > 0) {
				this.showAlertDialog({
					message: this.$t("$productGroup.has_children_fail_message"),
				});
				return;
			}

			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					// this.removeItemFromTree(this.productGroupTree, item.id);
					this.deleting = true;
					remote.productGroups.delete(item.id, {
						onSuccess: () => {
							this.$emit("deleted");
							this.loadProductGroupTree();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product_group"),
								}),
							});
						},
						onFail: (error) => {
							if (error) console.error(error);
						},
					});
					this.deleting = false;
				},
			});
		},
		loadProductGroupTree() {
			remote.productGroups.getMyProductGroupTree({
				onSuccess: (result) => {
					this.productGroupTree = result;
					this.handleParentCount(this.productGroupTree, 0);
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		handleParentCount(productGroupTree, level) {
			productGroupTree.level = level;
			if (level === 5) productGroupTree.id = productGroupTree._id;
			if (productGroupTree.children) level++;
			for (const child of productGroupTree.children) {
				this.handleParentCount(child, level);
			}
		},
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		handleFileUpload(event) {
			const file = event.target.files[0];
			if (!file) return;

			const reader = new FileReader();
			reader.onload = (e) => {
				try {
					const data = new Uint8Array(e.target.result);
					const workbook = XLSX.read(data, { type: "array" });
					const productGroupSheet = workbook.Sheets["Product Group"];

					if (!productGroupSheet) {
						this.showSnackBar({
							message: this.$t("missing_product_group_sheet_message"),
						});
						return;
					}

					const productGroupData = XLSX.utils.sheet_to_json(productGroupSheet, { header: 1 });

					if (productGroupData.length < 2) {
						this.showSnackBar({
							message: this.$t("no_product_group_data_message"),
						});
						return;
					}

					const languageMap = {
						Arabic: "ar",
						German: "de",
						English: "en",
						Spanish: "es",
						French: "fr",
						Hindi: "hi",
						Indonesian: "ind",
						Italian: "it",
						Japanese: "ja",
						Korean: "ko",
						Portuguese: "pt",
						Russian: "ru",
						Turkish: "tr",
						Vietnamese: "vi",
						Chinese: "zh",
					};

					const headers = productGroupData[0].map((h) => {
						const cleanHeader = String(h).trim();
						return languageMap[cleanHeader] || cleanHeader;
					});

					const values = productGroupData[1];
					const defaultLang = this.$store.getters.companyDefaultLanguage;

					if (!headers.includes(defaultLang)) {
						this.showSnackBar({
							message: this.$t("import.missing_default_language", {
								lang: this.$t(`$language.${defaultLang}`),
							}),
						});
						return;
					}

					const productGroupInfo = {};
					headers.forEach((lang, index) => {
						const value = values[index];
						if (value !== undefined && value !== null) {
							productGroupInfo[lang] = String(value).trim();
						}
					});

					console.log("Processed import data:", productGroupInfo);

					remote.productGroups.importBulk(productGroupInfo, {
						onSuccess: (result) => {
							this.showSnackBar({
								message: this.$t("import_success_message"),
							});
							this.loadProductGroupTree();
						},
						onFail: (error) => {
							console.error("Import failed:", error);
							this.showSnackBar({
								message: this.$t("import_fail_message", { error: error.message }),
								color: "error",
							});
						},
					});
				} catch (error) {
					console.error("Import error:", error);
					this.showSnackBar({
						message: this.$t("file_processing_error_message", { error: error.message }),
						color: "error",
					});
				}
			};

			reader.onerror = (error) => {
				console.error("File read error:", error);
				this.showSnackBar({
					message: this.$t("file_read_error_message"),
					color: "error",
				});
			};

			reader.readAsArrayBuffer(file);
			event.target.value = "";
		},
	},
};
</script>

<style scoped>
.custom-add-btn {
	border: 1.6px solid #005d8f;
	color: #005d8f;
}
</style>
