import Model from "../_model";
import ContainerProductModel from "./container_product.model";
import ContainerExpenseModel from "./container_expense.model";
import moment from "moment";

export default class ContainerModel extends Model {
	constructor(provider) {
		super(provider, "shipments/{shipmentId}/containers");
		this.products = new ContainerProductModel(provider);
		this.expenses = new ContainerExpenseModel(provider);
	}

	setProvider(value) {
		super.setProvider(value);
		this.products.setProvider(value);
		this.expenses.setProvider(value);
	}

	_parse(object) {
		object.estimatedArrivalDay = object.estimatedArrivalDay ? moment(object.estimatedArrivalDay) : null;
		return super._parse(object);
	}

	_getUrl(shipmentId, { id }) {
		let url = this.apiMethod.replace("{shipmentId}", shipmentId);
		if (id) url = url.concat(`/${id}`);
		return url;
	}

	filter(shipmentId, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(shipmentId, {}),
			onSuccess,
			onFail,
		});
	}

	find(id, shipmentId, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(shipmentId, { id }),
			onSuccess,
			onFail,
		});
	}

	serialize(instance) {
		return {
			code: instance.code,
			status: instance.status,
			estimatedArrivalDay: instance.estimatedArrivalDay ? instance.estimatedArrivalDay.format("YYYY-MM-DD") : null,
			autoDetect: instance.autoDetect,
			note: instance.note || undefined,
		};
	}

	create(instance, shipmentId, { onSuccess, onFail }) {
		const body = this.serialize(instance);
		return super.create(body, {
			url: this._getUrl(shipmentId, {}),
			onSuccess,
			onFail,
		});
	}

	update(instance, shipmentId, { onSuccess, onFail }) {
		const body = this.serialize(instance);
		return super.update(body, {
			url: this._getUrl(shipmentId, { id: instance.id }),
			onSuccess,
			onFail,
		});
	}

	delete(id, shipmentId, { onSuccess, onFail }) {
		return super._delete(this._getUrl(shipmentId, { id }), {
			onSuccess,
			onFail,
		});
	}
}
