<template>
	<v-col align="center">
		<h2 :class="isMobile ? 'v-typography--title' : 'v-typography--title3'" class="pa-2 mb-2">
			{{ $t("$landing.$question.title") }}
		</h2>
		<p :class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'">
			{{ $t("$landing.$question.text") }}
		</p>
		<v-container class="mt-4">
			<v-expansion-panels flat class="w-100" :class="{ 'w-100': isMobile }">
				<v-expansion-panel v-for="i in 6" :key="i" class="my-2 custom-panel">
					<v-expansion-panel-header class="v-typography--body font-weight-bold">
						<template #actions="{ expanded }">
							<img :src="expanded ? minusIcon : plusIcon" :alt="expanded ? 'Minus Icon' : 'Plus Icon'" class="icon" />
						</template>
						{{ $t(`$landing.$question.$title.${i - 1}`) }}
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<div class="text-left" v-html="$t(`$landing.$question.$text.${i - 1}`)" />
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>
	</v-col>
</template>

<script>
import minusIcon from "@/assets/minus.webp";
import plusIcon from "@/assets/plus.webp";

export default {
	props: { isMobile: Boolean },
	data() {
		return {
			minusIcon,
			plusIcon,
		};
	},
};
</script>

<style scoped>
.red-circular-bg {
	background-color: #ce272d;
	color: white;
	border-radius: 50%;
	padding: 3px;
}

.custom-panel {
	border: 1px rgb(220, 220, 220) solid;
}

.icon {
	width: 20px;
	height: 20px;
	cursor: pointer;
	transition: filter 0.3s ease;
}

.text-h4 {
	font-size: 1.5rem;
}

.text-h8 {
	font-size: 1rem;
}

.v-expansion-panels {
	width: 50%;
}

.v-main {
	max-width: 100%;
	overflow-x: hidden;
}

.text-mobile-h8 {
	font-size: 0.9rem;
}

@media (max-width: 600px) {
	.v-expansion-panels {
		width: 100%;
	}
}
</style>
