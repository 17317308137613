<template>
	<page-template>
		<template slot="content">
			<common-table
				:title="$t('products')"
				showFilter
				:filtered="filterDialog.data.text || filterDialog.data.productGroupId ? true : false"
				:options.sync="tableOptions"
				:headers="headers"
				:items="products"
				:items-length="pagination.totalItemCount"
				:items-per-page="tableOptions.itemsPerPage"
				:items-per-page-options="tableFooterProps.itemsPerPageOptions"
				:loading="loading"
				:entity-name="$t('product')"
				button-text=" "
				@edit-item="productAction"
				@delete-item="deleteAction"
				@row-click="productAction"
			>
				<template #[`item.name`]="{ item }">
					{{ item.name[$lang] && item.name[$lang].length ? item.name[$lang] : item.name["_default"] }}
				</template>
				<template #[`item.productGroup`]="{ item }">
					{{ getProductGroupName(item.productGroupId) }}
				</template>
				<template #actions>
					<v-btn color="accent" outlined large elevation="0" @click="dialog.show(null)">
						<v-icon class="icon-plus mr-2" start size="medium"></v-icon>
						{{ $t("add_item", { item: $t("product") }) }}
					</v-btn>
					<v-btn color="accent" outlined large elevation="0" @click="triggerFileInput" class="ml-2">
						<v-icon class="icon-import mr-2" start size="medium"></v-icon>
						{{ $t("import_excel") }}
					</v-btn>
				</template>
			</common-table>
			<input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" accept=".xlsx, .xls" />
			<product-filter-dialog v-model="filterDialog.visibility" :data="filterDialog.data" @done="filterDialog.done" />

			<product-dialog v-model="dialog.visibility" :item="dialog.item" @saved="dialog.saved" @deleted="dialog.deleted" />
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";
import PageTemplate from "../../templates/PageTemplate";
import remote from "../../../data/remote";
import ProductDialog from "./dialog/ProductDialog.vue";
import ProductFilterDialog from "./dialog/ProductFilterDialog.vue";
import CommonTable from "../../partials/CommonTable.vue";
import * as XLSX from "xlsx";

export default {
	mixins: [uiMixin, paginationMixin],
	components: { PageTemplate, ProductDialog, ProductFilterDialog, CommonTable },
	data() {
		return {
			products: [],
			productGroupTree: {},
			productGroups: [],
			loading: true,
			unitOptions: [],
			filterDialog: {
				visibility: false,
				data: { text: null, productGroupId: null },
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (data) => {
					this.filterDialog.data = data;
					this.$router.push({ query: this.filterDialog.data });
					this.filterDialog.hide();
					this.loadItems();
				},
			},
			dialog: {
				visibility: false,
				item: null,
				show(product) {
					this.item = product;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
			},
			headers: [
				{ text: this.$t("code"), value: "code" },
				{ text: this.$t("name"), value: "name" },
				{ text: this.$t("product_group"), value: "productGroupName" },
				{ text: this.$t("actions"), value: "actions" },
			],
		};
	},
	computed: {
		filterIcon() {
			return this.filterDialog.data.text || this.filterDialog.data.productGroupId
				? "icon-filter"
				: "icon-filter-outline";
		},
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.products.filter({
				filter: this.filterDialog.data,
				pager: {
					number: this.tableOptions.page,
					size: this.tableOptions.itemsPerPage,
				},
				sorter: this.tableOptions.sortBy.length
					? {
							property: this.tableOptions.sortBy,
							method: this.tableOptions.sortDesc ? "desc" : "asc",
					  }
					: undefined,
				onSuccess: (result) => {
					this.pagination = result.pagination;

					this.products = result.items
						.sort((a, b) => {
							if (a.createdAt && b.createdAt) {
								return a.createdAt < b.createdAt ? 1 : -1;
							} else {
								return !a.createdAt ? 1 : -1;
							}
						})
						.map((product) => {
							const productGroupName = this.getProductGroupName(product.productGroupId);
							return {
								...product,
								productGroupName: productGroupName,
							};
						});

					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},

		loadProductGroupTree() {
			remote.productGroups.getMyProductGroupTree({
				onSuccess: (result) => {
					this.productGroupTree = result;
					this.loadItems();
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		loadUnits() {
			remote.structures.getUnits({
				onSuccess: (result) => (this.unitOptions = result.items),
			});
		},
		deleteAction(item) {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.products.delete(item.id, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product"),
								}),
							});
							this.loadItems();
							this.$emit("deleted", result);
						},
						onFail: () => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.delete_failed", {
									item: this.$t("product"),
								}),
								color: "error",
							});
						},
					});
				},
			});
		},
		productAction(product) {
			this.dialog.show(product);
		},
		getProductGroupName(productGroupId) {
			const searchTree = (nodes) => {
				for (let node of nodes) {
					if (node.id === productGroupId) {
						const currentLang = this.$lang || "en";
						return node.name?.[currentLang] || node.name?._default || node.name?.en || node.name?.tr;
					}
					if (node.children && node.children.length > 0) {
						const found = searchTree(node.children);
						if (found) return found;
					}
				}
				return null;
			};

			return searchTree(this.productGroupTree.children || []);
		},
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		findProductGroupId(nodes, groupName) {
			for (let node of nodes) {
				const nodeName = node.name.en || node.name._default;
				if (nodeName === groupName) return node.id;
				if (node.children && node.children.length) {
					const found = this.findProductGroupId(node.children, groupName);
					if (found) return found;
				}
			}
			return null;
		},
		async handleFileUpload(event) {
			const file = event.target.files[0];
			if (!file) return;
			this.showProgressDialog();
			const reader = new FileReader();
			reader.onload = async (e) => {
				try {
					const data = new Uint8Array(e.target.result);
					const workbook = XLSX.read(data, { type: "array" });
					const productSheet = workbook.Sheets["Product"];
					if (!productSheet) {
						this.hideProgressDialog();
						this.showSnackBar({
							message: this.$t("missing_product_sheet_message"),
							color: "error",
						});
						return;
					}
					const productData = XLSX.utils.sheet_to_json(productSheet);
					if (productData.length === 0) {
						this.hideProgressDialog();
						this.showSnackBar({
							message: this.$t("no_product_data_message"),
							color: "error",
						});
						return;
					}
					const languageMap = {
						Arabic: "ar",
						German: "de",
						English: "en",
						Spanish: "es",
						French: "fr",
						Hindi: "hi",
						Indonesian: "ind",
						Italian: "it",
						Japanese: "ja",
						Korean: "ko",
						Portuguese: "pt",
						Russian: "ru",
						Turkish: "tr",
						Vietnamese: "vi",
						Chinese: "zh",
					};
					const productPromises = productData.map(async (row) => {
						let productGroupId = null;
						if (row.Product_Group_Name_English) {
							productGroupId = this.findProductGroupId(
								this.productGroupTree.children || [],
								row.Product_Group_Name_English
							);
							if (!productGroupId) {
								this.showSnackBar({
									message: this.$t("product_group_not_found_message", {
										group: row.Product_Group_Name_English,
										code: row.Product_Code,
									}),
									color: "error",
								});
								return null;
							}
						}
						const name = {};
						const defaultLang = this.$store.getters.companyDefaultLanguage;
						const defaultField = `Product_Name_${Object.keys(languageMap).find(
							(key) => languageMap[key] === defaultLang
						)}`;
						if (row[defaultField]) {
							name[defaultLang] = row[defaultField];
						} else if (row.Product_Name_English) {
							name[defaultLang] = row.Product_Name_English;
						}
						for (const [langName, langCode] of Object.entries(languageMap)) {
							const fieldName = `Product_Name_${langName}`;
							if (row[fieldName] && row[fieldName].trim() !== "") {
								name[langCode] = row[fieldName];
							}
						}
						const unitCodes = [];
						if (row.Units && row.Units.trim() !== "") {
							const unitNames = row.Units.split(";").map((u) => u.trim());
							unitNames.forEach((unitName) => {
								if (this.unitOptions && this.unitOptions.length) {
									const unit = this.unitOptions.find(
										(u) => this.$t(`$unit.${u.name}`) === unitName || u.name === unitName
									);
									if (unit) {
										unitCodes.push(unit.code);
									}
								}
							});
						}
						return {
							code: row.Product_Code?.toString() || "",
							name: name,
							productGroupId: productGroupId,
							unitCodes: unitCodes,
						};
					});
					const products = await Promise.all(productPromises);
					const validProducts = products.filter((p) => p && p.name[this.$store.getters.companyDefaultLanguage]);
					const invalidCount = products.filter((p) => p === null).length;
					if (validProducts.length === 0) {
						this.hideProgressDialog();
						this.showSnackBar({
							message: this.$t("no_valid_products_message", {
								language: this.$t(`$language.${this.$store.getters.companyDefaultLanguage}`),
							}),
							color: "error",
						});
						return;
					}
					remote.products.importBulk(validProducts, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("import_success_message", {
									count: result.length,
									skipped: invalidCount > 0 ? ` (${invalidCount} skipped due to missing group or data)` : "",
								}),
							});
							this.loadItems();
						},
						onFail: (error) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("import_fail_message", {
									error: error.message || "Unknown error",
								}),
								color: "error",
							});
						},
					});
				} catch (error) {
					this.hideProgressDialog();
					this.showSnackBar({
						message: this.$t("file_processing_error_message", { error: error.message }),
						color: "error",
					});
				}
			};
			reader.onerror = (error) => {
				this.hideProgressDialog();
				this.showSnackBar({
					message: this.$t("file_read_error_message"),
					color: "error",
				});
			};
			reader.readAsArrayBuffer(file);
			event.target.value = "";
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.filterDialog.data.text = this.$route.query.text;
		this.filterDialog.data.productGroupId = this.$route.query.productGroupId;
		this.loadProductGroupTree();
		this.loadUnits();
	},
};
</script>

<style scoped>
.custom-add-btn {
	border: 1.6px solid #005d8f;
	color: #005d8f;
}
</style>
