<template>
	<ext-dialog
		v-if="currentItem"
		v-model="value"
		persistent
		:fullscreen="false"
		dialogType="small"
		:title="dialogTitle"
		:primaryButtonText="primaryButtonText"
		:secondaryButtonText="secondaryButtonText"
		@primaryButtonAction="primaryActionHandler"
		@secondaryButtonAction="secondaryActionHandler"
		@closed="cancelAction"
	>
		<template #body>
			<v-card-text class="pa-0">
				<v-form ref="form" v-model="valid">
					<v-select
						v-model="currentItem.type"
						:items="typeOptions"
						:label="$t('type')"
						outlined
						class="rounded-xl"
						:rules="rules.type"
						:disabled="hasInvitationCode || Boolean(currentItem.id) || onlyOneOption"
						append-icon="icon-angle-down"
						:menu-props="{ offsetY: true }"
					/>
					<span class="ma-0">{{ $t("email") }}</span>
					<v-text-field
						v-model="currentItem.email"
						:disabled="hasInvitationCode || !currentItem.new"
						class="rounded-xl"
						:rules="rules.email"
					/>
					<v-checkbox
						on-icon="icon-checkbox-checked"
						off-icon="icon-checkbox-unchecked"
						v-model="hasInvitationCode"
						:label="$t('i_have_invitation_code')"
						class="mt-4"
					></v-checkbox>
					<v-text-field
						v-if="hasInvitationCode"
						v-model="invitationCode"
						:label="$t('invitation_code')"
						:rules="rules.invitationCode"
						class="rounded-xl mt-4"
					></v-text-field>
				</v-form>
			</v-card-text>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import { mapGetters } from "vuex";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	mixins: [uiMixin],
	props: ["value", "item", "isEmployee", "defaultType"],
	components: {
		ExtDialog,
	},
	data() {
		return {
			currentItem: null,
			valid: null,
			typeOptions: [],
			saving: false,
			sending: false,
			discarding: false,
			hasInvitationCode: false,
			invitationCode: "",
			rules: {
				type: [
					(v) => {
						if (this.hasInvitationCode) return true;
						return Boolean(v) || this.$t("$validation.required", { item: this.$t("type") });
					},
				],
				email: [
					(v) => {
						if (this.hasInvitationCode) return true;
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
				invitationCode: [
					(v) => {
						if (!this.hasInvitationCode) return true;
						return (Boolean(v) && Boolean(v.trim())) || this.$t("$validation.required", { item: this.$t("code") });
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
		onlyOneOption() {
			return this.typeOptions.length === 1;
		},
		dialogTitle() {
			// Use a safe check so we don't try to access currentItem.new when currentItem is null
			return this.currentItem ? this.$t(this.currentItem.new ? "send_invitation" : "invitation") : "";
		},
		primaryButtonText() {
			// For new invitations, primary text is "send" (or "accept_invitation" if using an invitation code)
			// For existing invitations, primary text is "send_again"
			if (this.currentItem) {
				return this.currentItem.new
					? this.hasInvitationCode
						? this.$t("accept_invitation")
						: this.$t("send")
					: this.$t("send_again");
			}
			return "";
		},
		secondaryButtonText() {
			// Show a secondary button only for existing invitations (for deletion)
			return this.currentItem && !this.currentItem.new ? this.$t("delete") : "";
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
		// Auto-select first type option for a new invitation if not set.
		typeOptions(newOptions) {
			if (this.currentItem && this.currentItem.new && newOptions && newOptions.length && !this.currentItem.type) {
				this.currentItem.type = newOptions[0].value;
			}
		},
	},
	methods: {
		handleProps() {
			// If item exists, we're editing an existing invitation
			if (this.item) {
				this.currentItem = Object.assign(
					{},
					{
						new: false,
						id: this.item.id,
						email: this.item.email,
						type: this.item.type,
					}
				);
			} else {
				this.currentItem = {
					email: "",
					type: this.defaultType || null,
					new: true,
				};
				// If no default type and typeOptions exist, auto-select the first option.
				if (!this.currentItem.type && this.typeOptions.length > 0) {
					this.currentItem.type = this.typeOptions[0].value;
				}
			}
		},
		reset() {
			if (this.$refs.form) {
				this.$refs.form.reset();
			}
			this.currentItem = null;
			this.hasInvitationCode = false;
			this.invitationCode = "";
		},
		fillTypeOptions() {
			this.typeOptions = [];
			if (this.isEmployee) {
				this.typeOptions.push({
					text: this.$t("$invitationType.employee"),
					value: "employee",
				});
			} else if (this.company) {
				this.typeOptions.push({
					text: this.$t("$invitationType.company_partner"),
					value: "company_partner",
				});
				this.typeOptions.push({
					text: this.$t("$invitationType.customs_partner"),
					value: "customs_partner",
				});
			} else {
				this.typeOptions.push({
					text: this.$t("$invitationType.company_partner"),
					value: "company_partner",
				});
			}
		},
		primaryActionHandler() {
			// For new invitation, call saveAction; for existing, send email again.
			if (this.currentItem.new) {
				this.saveAction();
			} else {
				this.sendEmailAction();
			}
		},
		secondaryActionHandler() {
			// For existing invitations, secondary action deletes the invitation.
			this.discardAction();
		},
		saveAction() {
			if (this.hasInvitationCode) {
				if (this.$refs.form.validate()) {
					this.saving = true;
					remote.invitations.accept(this.invitationCode.trim(), {
						onSuccess: (result) => {
							this.saving = false;
							this.$emit("saved", result);
							this.showSnackBar({
								message: this.$t("accept_invitation_success_message"),
							});
							this.closeAction();
						},
						onFail: () => (this.saving = false),
					});
				}
			} else {
				if (this.$refs.form.validate()) {
					this.saving = true;
					remote.invitations.create(
						{
							email: this.currentItem.email,
							type: this.currentItem.type,
						},
						{
							onSuccess: (result) => {
								this.saving = false;
								this.$emit("saved", result);
								this.showSnackBar({
									message: this.$t("$message.created", {
										item: this.$t("invitation"),
									}),
								});
							},
							onFail: () => (this.saving = false),
						}
					);
				}
			}
		},
		discardAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.discarding = true;
					remote.invitations.delete(this.currentItem.id, {
						onSuccess: (result) => {
							this.discarding = false;
							this.$emit("deleted", result);
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("invitation"),
								}),
							});
						},
						onFail: () => (this.discarding = false),
					});
				},
			});
		},
		sendEmailAction() {
			this.sending = true;
			remote.invitations.sendMail(this.currentItem.id, {
				onSuccess: () => {
					this.sending = false;
					this.$emit("emailSent");
					this.showSnackBar({
						message: this.$t("invitation_mail_sent", {
							email: this.currentItem.email,
						}),
					});
				},
				onFail: () => (this.sending = false),
			});
		},
		closeAction() {
			this.$emit("input", false);
		},
		cancelAction() {
			this.reset();
			this.closeAction();
		},
	},
	created() {
		this.fillTypeOptions();
	},
	mounted() {
		this.handleProps();
	},
};
</script>
