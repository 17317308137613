var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-template',[(_vm.currentItem)?_c('template',{slot:"content"},[_c('v-container',[_c('v-row',{staticClass:"d-flex align-center justify-space-between flex-wrap px-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","outlined":""},on:{"click":_vm.backAction}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("icon-angle-left")]),_vm._v(" "+_vm._s(_vm.$t("proforma_invoices"))+" ")],1)],1),(_vm.menuItems && _vm.menuItems.length && !_vm.editMode)?_c('v-col',{staticClass:"d-flex align-center justify-end flex-wrap",class:{
						'pt-2': _vm.$vuetify.breakpoint.mdAndDown,
						'justify-center align-center': _vm.$vuetify.breakpoint.smAndDown,
					},attrs:{"cols":"auto"}},_vm._l((_vm.menuItems),function(item,index){return _c('v-btn',{key:index,staticClass:"mx-1",class:{
							'mb-1': _vm.$vuetify.breakpoint.smAndDown,
						},attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.menuItemAction(item.key)}}},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"ml-1 v-typography--body-sm"},[_vm._v(_vm._s(item.title))])],1)}),1):_vm._e()],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{class:{
						'pt-0': _vm.$vuetify.breakpoint.mdAndDown,
					},attrs:{"sm":"12","lg":"7"}},[_c('proforma-invoice-details',{attrs:{"currentItem":_vm.currentItem,"iAmSender":_vm.iAmSender,"iAmReceiver":_vm.iAmReceiver,"editMode":_vm.editMode},on:{"start-edit":function($event){_vm.editMode = true},"cancel-edit":_vm.cancelAction,"save":_vm.saveActionFromDetails}}),_c('proforma-invoice-attachments',{attrs:{"proformaInvoice":_vm.currentItem},on:{"changed":_vm.reloadProforma}}),(!_vm.isMobile)?_c('proforma-invoice-action-timeline',{attrs:{"sm":"12","lg":"7"},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}}):_vm._e()],1),_c('v-col',{attrs:{"sm":"12","lg":"5"}},[_c('proforma-invoice-products',{attrs:{"currentItem":_vm.currentItem,"iAmSender":_vm.iAmSender},on:{"reloadCurrentItem":function($event){return _vm.loadItem(_vm.currentItem.id)}}}),(_vm.isMobile)?_c('proforma-invoice-action-timeline',{attrs:{"sm":"12","lg":"7"},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}}):_vm._e(),_c('package-list',{attrs:{"document-object":_vm.currentItem,"document-type":"proforma"},on:{"change":_vm.reloadProforma},model:{value:(_vm.packageList.visible),callback:function ($$v) {_vm.$set(_vm.packageList, "visible", $$v)},expression:"packageList.visible"}})],1)],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }