<template>
	<div>
		<ext-dialog
			v-model="value"
			:title="$t('product')"
			:primaryButtonText="$t('save')"
			@primaryButtonAction="saveAction"
			@closed="closeAction"
		>
			<template #body v-if="currentItem">
				<v-form ref="form" v-model="valid">
					<product-select
						:key="productSelectKey"
						v-model="currentItem.productId"
						:label="$t('product')"
						:rules="rules.productId"
						eager="true"
						@change="productChangeAction"
						@openDialog="openCreateProduct"
					/>
					<v-row>
						<v-col cols="6" class="py-0">
							<v-text-field
								v-model="currentItem.quantity"
								:label="$t('quantity')"
								:rules="rules.quantity"
								type="number"
								min="0"
							/>
						</v-col>
						<v-col cols="6" class="py-0">
							<unit-select
								v-model="currentItem.unitCode"
								eager="ready"
								:options="unitOptions"
								:rules="rules.unit"
								:disabled="!currentItem.productId"
							/>
						</v-col>
					</v-row>
					<v-text-field
						v-model="currentItem.amount"
						:label="$t('amount')"
						:rules="rules.amount"
						type="number"
						min="0"
						:suffix="shipment.currency.symbol"
					/>
				</v-form>
			</template>
		</ext-dialog>
		<product-dialog v-model="productDialog.visibility" :item="productDialog.item" @saved="handleProductSaved" />
	</div>
</template>

<script>
import ProductSelect from "../../../controls/ProductSelect";
import UnitSelect from "../../../controls/UnitSelect";
import remote from "../../../../data/remote";
import cloneDeep from "lodash.clonedeep";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import ProductDialog from "../../product/dialog/ProductDialog.vue";

export default {
	props: { value: Boolean, item: Object, shipment: Object, container: Object, iAmSenderCompany: Boolean },
	components: { ProductSelect, UnitSelect, ExtDialog, ProductDialog },
	data() {
		return {
			valid: null,
			productSelectKey: 0,
			rules: {
				productId: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("product") })],
				quantity: [
					(v) => (!isNaN(v) && Number(v) > 0) || this.$t("$validation.required", { item: this.$t("quantity") }),
				],
				unit: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("unit") })],
				amount: [(v) => !Number.isNaN(v) || this.$t("$validation.required", { item: this.$t("amount") })],
			},
			currentItem: null,
			currentProduct: null,
			ready: false,
			saving: false,
			productDialog: {
				visibility: false,
				item: null,
			},
		};
	},
	computed: {
		unitOptions() {
			return this.currentProduct?.units;
		},
		createButtonText() {
			return this.$t("add_new_product");
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item) {
				this.currentItem = cloneDeep(this.item);
				this.loadCurrentProduct();
			} else this.useItemTemplate();
		},
		reset() {
			if (this.$refs.form) this.$refs.form.reset();
			this.currentItem = null;
			this.currentProduct = null;
		},
		useItemTemplate() {
			this.currentItem = {
				productId: null,
				name: "",
				unitCode: "",
				quantity: null,
				amount: null,
			};
		},
		loadCurrentProduct() {
			if (this.currentItem?.productId) {
				remote.products.find(this.currentItem.productId, {
					onSuccess: (result) => {
						this.currentProduct = result;
						this.ready = true;
					},
					onFail: (_) => (this.ready = true),
				});
			}
		},
		productChangeAction(product) {
			this.currentItem.name = product.name;
			this.currentProduct = product;
			if (product.units.length === 1) {
				this.currentItem.unitCode = product.units[0].code;
			} else {
				this.currentItem.unitCode = null;
			}
		},
		openCreateProduct() {
			this.productDialog.item = null;
			this.productDialog.visibility = true;
		},
		handleProductSaved(product) {
			this.productDialog.visibility = false;
			this.currentItem.productId = product.id;
			this.productChangeAction(product);
			this.productSelectKey++; 
		},
		saveAction() {
			if (!this.saving) {
				if (this.valid) {
					const onSuccess = (result) => this.$emit("saved", result);
					if (this.currentItem.amount == null || this.currentItem.amount === "") this.currentItem.amount = 0;
					if (this.currentItem.id) {
						remote.shipments.containers.products.update(this.shipment.id, this.container.id, this.currentItem, {
							onSuccess,
						});
					} else {
						remote.shipments.containers.products.create(this.shipment.id, this.container.id, this.currentItem, {
							onSuccess,
						});
					}
				} else {
					this.$refs.form.validate();
				}
			}
		},
		closeAction() {
			if (!this.saving) this.$emit("input", false);
		},
	},
	mounted() {
		this.handleProps();
	},
};
</script>
