<template>
	<!-- <v-list-item>
		<v-list-item-icon class="mt-6 mb-4">
			<v-icon v-if="icon">{{ icon }}</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-subtitle class="mb-1">{{ title }}</v-list-item-subtitle>
			<v-list-item-title>{{ value }}</v-list-item-title>
		</v-list-item-content>
	</v-list-item> -->
	<v-input
		:prepend-icon="icon"
		:messages="messages"
		:error-count="messages ? messages.length : 0"
		class="ext-title-value"
		:height="height || 44"
		:class="{ 'ext-title-value--centered': centered }"
	>
		<div class="d-inline-block">
			<div class="d-block v-typography--body-xs">
				{{ title }}
			</div>
			<div class="d-block">
				{{ typeof value === "function" ? value() : value }}
			</div>
		</div>
	</v-input>
</template>

<script>
export default {
	props: ["icon", "title", "value", "messages", "height", "centered"],
};
</script>

<style scoped>
.ext-title-value {
	margin-top: 4px;
}
.ext-title-value .v-icon {
	margin-top: 24px;
}
.ext-title-value--centered >>> .v-input__slot {
	text-align: center;
	justify-content: center;
}
</style>
