<template>
	<page-template>
		<template slot="buttons"> </template>

		<template slot="content">
			<v-row class="mt-6" no-gutters align="center" justify="space-between">
				<v-col cols="auto">
					<v-card-title class="pa-0 font-weight-bold">
						{{ $t("partners") }}
					</v-card-title>
				</v-col>
				<v-col cols="auto" class="d-flex justify-end">
					<v-btn v-if="company && currentTab == 0" text outlined @click="migrateDialog.show">
						<v-icon color="primary">icon-merge</v-icon>
						<span>{{ $t("migrate_partner") }}</span>
					</v-btn>
				</v-col>
			</v-row>
			<v-card class="ant-card pa-2 pb-0 mt-4" outlined elevation="0">
				<v-tabs v-model="currentTab" color="anchor" class="mb-4" v-if="!showInvitationsTable">
					<v-tab v-for="(tab, i) in tabs" :key="i">
						{{ $t(tab) }}
					</v-tab>
					<v-col align="end" class="pt-1">
						<v-btn class="text-end text-capitalize" text @click="openInvitationsTable">
							<span class="v-typography--paragraph"> {{ $t("pending_invitations") }} ({{ invitationsCount }}) </span>
							<v-icon class="ml-1" size="16">icon-arrow-right</v-icon>
						</v-btn>
					</v-col>
				</v-tabs>
				<v-tabs-items v-model="currentTab" v-if="!showInvitationsTable" class="cardWidth">
					<v-tab-item>
						<partners-table
							:types="partnerTableTypes"
							:corporationId="corporation.id"
							:partnerDialogState="newItemDialogState"
							@partnerDialogClosed="newItemDialogState = false"
							:invitationCodeDialogState="invitationCodeDialogState"
							@invitationCodeDialogClosed="invitationCodeDialogState = false"
							:needRefresh="needRefresh"
							@refreshed="needRefresh = false"
							:showCreateButton="true"
							:showSendInvitationButton="true"
						/>
					</v-tab-item>
					<v-tab-item v-if="company">
						<partners-table
							:types="[partnerTypes.CustomsAgency]"
							:corporationId="corporation.id"
							:showCreateButton="false"
							:showSendInvitationButton="true"
						/>
					</v-tab-item>
				</v-tabs-items>
				<v-container v-if="showInvitationsTable">
					<v-btn text class="text-start text-capitalize mb-2" @click="closeInvitationsTable">
						<v-icon class="mr-2" size="16">icon-arrow-left</v-icon>
						{{ $t("pending_invitations") }}
					</v-btn>
					<invitations-table :isEmployee="false" @show-dialog="invitationCodeDialogState = true" />
				</v-container>
			</v-card>
			<migrate-dialog v-model="migrateDialog.visibility" @migrated="migrateDialog.migrated" />
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import PageTemplate from "../../templates/PageTemplate.vue";
import { mapGetters, mapState } from "vuex";
import PartnersTable from "./partial/PartnersTable.vue";
import InvitationsTable from "./partial/InvitationsTable.vue";
import { invitationTypes, partnerTypes } from "../../../../src/utils/enum";
import MigrateDialog from "./dialogs/MigrateDialog.vue";
import remote from "../../../data/remote";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		PartnersTable,
		InvitationsTable,
		MigrateDialog,
	},
	data() {
		return {
			currentTab: 0,
			tabs: [],
			companyPartners: [],
			customsAgencyPartners: [],
			newItemDialogState: false,
			invitationCodeDialogState: false,
			needRefresh: false,
			showInvitationsTable: false,
			invitationsCount: 0,
			migrateDialog: {
				visibility: false,
				show: () => {
					this.migrateDialog.visibility = true;
				},
				migrated: (result) => {
					this.migrateDialog.visibility = false;
					this.needRefresh = true;
					this.showSnackBar({
						message: this.$t("$message.migrated", {
							item: this.$t("business_partner"),
							value: result.name,
						}),
					});
				},
			},
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState(["corporation"]),
		partnerTypes: () => partnerTypes,
		partnerTableTypes() {
			return this.company
				? [partnerTypes.Company, partnerTypes.AbstractCompany]
				: this.customsAgency
				? [partnerTypes.Company, partnerTypes.CustomsAgency]
				: [];
		},
	},
	created() {
		this.loadItems();
		this.updateTabs();
		this.$trackPageView(this.$route.path);
	},
	methods: {
		updateTabs() {
			if (this.company) {
				this.tabs = ["commercial_partners", "customs_agencies"];
			} else if (this.customsAgency) {
				this.tabs = ["partners"];
			}
		},
		openInvitationsTable() {
			this.showInvitationsTable = true;
			this.currentTab = null;
		},
		closeInvitationsTable() {
			this.showInvitationsTable = false;
		},
		loadItems() {
			remote.invitations.filter(
				{
					status: "active",
					types: `${invitationTypes.CompanyPartner},${invitationTypes.CustomsPartner}`,
				},
				{
					pager: { number: 1, size: 1 },
				},
				{
					onSuccess: (result) => {
						this.invitationsCount = result.pagination.totalItemCount;
					},
					onFail: (error) => {
						if (error) console.error(error);
					},
				}
			);
		},
	},
};
</script>

<style scoped>
.cardWidth {
	width: 100% !important;
}
</style>
