<template>
	<v-menu transition="slide-y-transition" offset-y rounded="xl">
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon plain v-bind="attrs" v-on="on" x-large>
				<template v-if="logoUrl">
					<v-avatar size="40">
						<v-img :src="logoUrl"></v-img>
					</v-avatar>
				</template>
				<v-icon v-else large>icon-user</v-icon>
			</v-btn>
		</template>

		<v-list width="300">
			<v-list-item v-if="employee" class="px-6">
				<v-list-item-content>
					<v-list-item-title class="body">
						{{ employee?.user?.email }}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action>
					<v-icon color="success">icon-new-releases</v-icon>
				</v-list-item-action>
			</v-list-item>

			<v-divider v-if="employee" />

			<list-item
				class="px-6"
				v-for="(item, i) in listItems"
				:key="i"
				:icon="item.icon"
				:title="item.title"
				:url="item.url"
				@click="item.click"
			/>

			<v-divider />

			<list-item class="px-6 py-2" icon="icon-logout" :title="$t('sign_out')" @click="signOutAction" />
		</v-list>
	</v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";
import uiMixin from "../../../store/interfaces/ui.mixin";
import ListItem from "../../controls/ListItem.vue";
import remote from "../../../data/remote";
let cachedLogoUrl = null;

export default {
	mixins: [uiMixin],
	components: { ListItem },
	data() {
		return {
			listItems: [],
			logoUrl: null,
		};
	},
	computed: {
		...mapState(["corporation", "employee"]),
	},
	methods: {
		...mapActions("auth", ["signOut"]),

		handleMenuItems() {
			this.listItems.push({
				icon: "icon-user",
				title: this.$t("user_profile"),
				click: () => {},
				url: "/user_profile",
			});
			if (this.corporation) {
				this.listItems.push({
					icon: "icon-company",
					title: this.$t("company_profile"),
					click: () => {},
					url: "/company_profile",
				});
			}
			this.listItems.push({
				icon: "icon-comment",
				title: this.$t("feedback"),
				click: () => this.$emit("showFeedbackDialog", false),
				url: null,
			});
			// this.listItems.push({
			// 	icon: "icon-settings",
			// 	title: this.$t("settings"),
			// 	click: () => this.$emit("showSettingDialog", false),
			// 	url: null,
			// });
		},
		signOutAction() {
			this.showConfirmDialog({
				message: this.$t("sign_out_confirm_message"),
				onConfirm: () => {
					this.signOut(() => {
						this.showSnackBar({
							message: this.$t("sign_out_success_message"),
						});
					});
				},
			});
		},
		loadLogo() {
			if (this.corporation && this.corporation.logo) {
				if (cachedLogoUrl) {
					this.logoUrl = cachedLogoUrl;
				} else {
					remote.files.get(this.corporation.logo.key, {
						onSuccess: (result) => {
							cachedLogoUrl = window.URL.createObjectURL(new Blob([result]));
							this.logoUrl = cachedLogoUrl;
						},
						onFail: (error) => {
							console.error("Failed to load logo:", error);
						},
					});
				}
			}
		},
	},
	created() {
		this.handleMenuItems();
		this.loadLogo();
	},
};
</script>
