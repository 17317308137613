<template>
	<div>
		<template elevation="0" class="ant-card py-2">
			<v-card-title class="v-typography--title2 mt-2 px-0">{{ $t("dashboard") }}</v-card-title>
			<v-card-subtitle v-if="noData" class="py-2 px-0"
				><v-icon small class="mr-2"> icon-comment-info </v-icon>{{ $t("empty_data_warning") }}</v-card-subtitle
			>
			<v-row v-if="dashboard.currencyReports" class="ma-0">
				<v-col cols="12" sm="6" md="6" lg="3" class="pa-2">
					<container-count-report
						:data="dashboard.exportContainerCountReport"
						:title="$t('exported_containers')"
						chart-color="info"
						backgroundColor="anchor"
						type="export"
						:dark="isDark"
					/>
				</v-col>
				<v-col cols="12" sm="6" md="6" lg="3" class="pa-2">
					<container-count-report
						:data="dashboard.importContainerCountReport"
						:title="$t('imported_containers')"
						chart-color="info"
						backgroundColor="anchor"
						type="import"
						:dark="isDark"
					/>
				</v-col>

				<v-col cols="12" lg="6" class="pa-2">
					<trade-report
						v-if="dashboard.currencyReports"
						:currencyReports="dashboard.currencyReports"
						:title="$t('total_amount')"
						:dark="isDark"
					/>
				</v-col>
			</v-row>
		</template>
		<v-row class="ma-0">
			<v-col class="py-2" :class="isMobile ? 'px-0 ' : 'pl-0'" cols="12" xl="6" v-if="outgoingShipmentsVisibility">
				<v-card elevation="0" class="ant-card pb-2 px-4">
					<v-card-title>{{ $t("outgoing_shipments") }}</v-card-title>
					<shipment-status-table role="sender" />
				</v-card>
			</v-col>
			<v-col class="py-2" :class="isMobile ? 'px-0 ' : 'pr-0'" cols="12" xl="6" v-if="incomingShipmentsVisibility">
				<v-card elevation="0" class="ant-card pb-2 px-4">
					<v-card-title>{{ $t("incoming_shipments") }}</v-card-title>
					<shipment-status-table role="receiver" />
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import ShipmentStatusTable from "../shipment/partial/ShipmentStatusTable.vue";
import ContainerCountReport from "./partial/ContainerCountReport.vue";
import TradeReport from "./partial/TradeReport.vue";
import { mapGetters, mapState } from "vuex";
import remote from "../../../data/remote";

export default {
	components: { ShipmentStatusTable, ContainerCountReport, TradeReport },
	data() {
		return {
			noData: false,
			dashboard: {
				currencyReports: undefined,
				exportContainerCountReport: undefined,
				importContainerCountReport: undefined,
			},
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState(["employee"]),
		outgoingShipmentsVisibility() {
			return this.employee.type !== "importer";
		},
		incomingShipmentsVisibility() {
			return this.employee.type !== "exporter";
		},
		isDark() {
			return this.$vuetify.theme.isDark;
		},
		isMobile() {
			return this.$vuetify.breakpoint.lgAndDown;
		},
	},
	created() {
		if (this.company) this.loadDashboard();
	},
	methods: {
		generateReport() {
			const today = new Date();
			const formatDate = (date) => {
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, "0");
				return `${year}-${month}`;
			};
			const generateRandomCount = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
			const createMonthBasedCounts = () => {
				const monthBasedCounts = {};
				for (let i = 0; i < 12; i++) {
					const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
					monthBasedCounts[formatDate(date)] = generateRandomCount(5, 30);
				}
				return monthBasedCounts;
			};

			const calculateSummaryCounts = (monthBasedCounts) => {
				const currentMonth = formatDate(today);
				const currentMonthCount = monthBasedCounts[currentMonth] || 0;
				const totalCount = Object.values(monthBasedCounts).reduce((sum, value) => sum + value, 0);

				return {
					currentMonthCount,
					currentYearCount: totalCount,
					lastOneYearCount: totalCount,
				};
			};

			const generateContainerReport = () => {
				const exportMonthBasedCounts = createMonthBasedCounts();
				const importMonthBasedCounts = createMonthBasedCounts();

				return {
					exportContainerCountReport: {
						...calculateSummaryCounts(exportMonthBasedCounts),
						monthBasedCounts: exportMonthBasedCounts,
					},
					importContainerCountReport: {
						...calculateSummaryCounts(importMonthBasedCounts),
						monthBasedCounts: importMonthBasedCounts,
					},
				};
			};

			const generateCurrencyReports = () => {
				const currencies = [
					{ code: "USD", name: "us_dollar", symbol: "$" },
					{ code: "EUR", name: "euro", symbol: "€" },
				];

				return currencies.map((currency) => {
					const salesMonthBasedTotals = {};
					const purchaseMonthBasedTotals = {};

					for (let i = 0; i < 12; i++) {
						const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
						salesMonthBasedTotals[formatDate(date)] = generateRandomCount(5000, 15000);
						purchaseMonthBasedTotals[formatDate(date)] = generateRandomCount(5000, 15000);
					}

					const salesTotal = Object.values(salesMonthBasedTotals).reduce((sum, value) => sum + value, 0);
					const purchaseTotal = Object.values(purchaseMonthBasedTotals).reduce((sum, value) => sum + value, 0);

					return {
						currency,
						salesReport: {
							currentMonthTotal: salesMonthBasedTotals[formatDate(today)] || 0,
							currentYearTotal: salesTotal,
							monthBasedTotals: salesMonthBasedTotals,
						},
						purchaseReport: {
							currentMonthTotal: purchaseMonthBasedTotals[formatDate(today)] || 0,
							currentYearTotal: purchaseTotal,
							monthBasedTotals: purchaseMonthBasedTotals,
						},
					};
				});
			};

			const finalReport = {
				currencyReports: generateCurrencyReports(),
				...generateContainerReport(),
			};

			return finalReport;
		},
		loadDashboard() {
			remote.utilities.getDashboard({
				onSuccess: (result) => {
					if (!result || !result.currencyReports || result.currencyReports.length === 0) {
						this.noData = true;
						result = this.generateReport();
					}
					this.dashboard = result;
					let currencyReports = result.currencyReports;
					this.dashboard.currencyReports = [];
					for (const currencyReport of currencyReports) {
						let add = false;
						for (const key in currencyReport.salesReport.monthBasedTotals) {
							if (currencyReport.salesReport.monthBasedTotals[key] > 0) {
								add = true;
								break;
							}
						}
						if (!add) {
							for (const key in currencyReport.purchaseReport.monthBasedTotals) {
								if (currencyReport.purchaseReport.monthBasedTotals[key] > 0) {
									add = true;
									break;
								}
							}
						}
						if (add) this.dashboard.currencyReports.push(currencyReport);
					}
				},
				onFail: () => {
					this.loading = false;
				},
			});
		},
	},
};
</script>
